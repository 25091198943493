import React from "react";
import Header from "../Component/Homepage/Header";

import Daftar from "../Component/SignUp/Daftar";

function SignUp() {
  return (
    <div>
      <Header/>
      <Daftar />
    </div>
  );
}

export default SignUp;
