import React from "react";
import Header from "../Component/Homepage/Header";
import Masuk from "../Component/LogIn/Masuk";

function SignUp() {
  return (
    <div>
      <Header/>
      <Masuk />
    </div>
  );
}

export default SignUp;
